import gql from "graphql-tag";

export const GET_EMAIL_CREDENTIALS = gql`
query GetEmailCredentials($companyName: String!) {
    getEmailCredentials(companyName: $companyName) {
    code
    success
    message
    results
  }
}`;

export const SEND_EMAIL = gql`
mutation sendEmail($data: JSONObject!, $companyName: String!, $mail: JSONObject!) {
  sendEmail(data: $data, companyName: $companyName, mail: $mail) {
    code
    success
    message
  }
}`;

export const CREATE_EMAIL_CRED = gql`
mutation Mutation($data: EmailInput!, $companyName: String!) {
  addEmailCredentials(data: $data, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_EMAIL_CRED = gql`
mutation UpdateEmailCredentials($id: ID!, $data: EmailInput!, $companyName: String!) {
  updateEmailCredentials(_id: $id, data: $data, companyName: $companyName) {
    code
    success
    message
  }
}`;





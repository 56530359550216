import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet,  } from '@react-pdf/renderer';
import {  fDate, SSGTime } from '../../../../../util/formatTime.js';
// import parse from 'html-react-parser';
// // utils


// ----------------------------------------------------------------------


Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5vAw.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5vAw.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col2: { width: '20%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        mt8: { marginBottom: 8 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700, textAlign: '3px' },
        alignRight: { textAlign: 'right' },
        textCenter: {textAlign: 'center'},
        rightMargin: {marginLeft: 5},
        textMargin: {margin: 5},
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          padding: '40px 24px 120px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
        },
        tableCell_2: {
          width: '20%',
          paddingRight: 16,
        },
        tableCell_3: {
          width: '30%',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultRemark: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRow1: {
          flexDirection: 'row',
        },
        section: {
          margin: 10,
          padding: 10,
          fontSize: 12,
        },
        bulletPoint: {
          marginBottom: 5,
        },
        nestedBulletPoint: {
          marginBottom: 5,
          marginLeft: 20,
        },
        lineBreak: {
          marginBottom: 10,
        }
      }),
    []
  );
// ----------------------------------------------------------------------



export default function AssessmentFormPDF({ traineeInfo, assForm, assessmentSummary, classinfo, companyProfile }) {
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;
  const {fullName, nricFin} = traineeInfo;
  const {course, classCode} = classinfo;
  const {assessmentRecordForm} = assForm;

  const styles = useStyles();

  // const createBulletPoints = (text) => {
  //   const lines = text.split('\n');
  //   let formattedElements = [];
  
  //   lines.forEach((line, index) => {
  //     if (line.startsWith('●')) {
  //       formattedElements.push(
  //         <View style={styles.bulletPoint} key={index}>
  //           <Text style={styles.bullet}>•</Text>
  //           <Text style={styles.bulletText}>{line.replace('●', '').trim()}</Text>
  //         </View>
  //       );
  //     } else if (line.trim() === '') {
  //       // Add a line break for empty lines
  //       formattedElements.push(<Text key={`line-break-${index}`} style={{ marginBottom: 10 }} />);
  //     } else {
  //       // Regular text
  //       formattedElements.push(
  //         <Text key={`text-${index}`} style={{ marginBottom: 10 }}>
  //           {line}
  //         </Text>
  //       );
  //     }
  //   });
  
  //   return formattedElements;
  // };

  // const parseText = (text) => {
  //   const lines = text.split('\n');
  //   const sections = [];
  //   let currentSection = null;

  //   lines.forEach((line) => {
  //     if (line.startsWith('●')) {
  //       if (currentSection) {
  //         sections.push(currentSection);
  //       }
  //       currentSection = { title: line.replace('●\t', '').trim(), subPoints: [] };
  //     } else if (line.startsWith('o')) {
  //       currentSection?.subPoints.push(line.replace('o\t', '').trim());
  //     }
  //   });

  //   if (currentSection) {
  //     sections.push(currentSection);
  //   }

  //   return sections;
  // };

  // const parseText = (text) => {
  //   const lines = text.split('\n');
  //   const sections = [];
  //   let currentSection = null;
  //   let introText = '';
  
  //   lines.forEach((line) => {
  //     line = line.trim();
  
  //     if (!introText && line && !line.startsWith('●\t') && !line.startsWith('▪\t') && !line.startsWith('o\t') && !line.startsWith('•\t')) {
  //       introText = line; // Capture introductory text if it's not a bullet point line
  //     } else if (line.startsWith('●\t') || line.startsWith('▪\t') || line.startsWith('•\t')) {
  //       if (currentSection) {
  //         sections.push(currentSection);
  //       }
  //       currentSection = { title: line.replace(/^[●▪•]\t/, '').trim(), subPoints: [] };
  //     } else if (line.startsWith('o\t')) {
  //       if (!currentSection) {
  //         currentSection = { title: '', subPoints: [] }; // Create an empty section if there wasn't one already
  //       }
  //       currentSection.subPoints.push(line.replace('o\t', '').trim());
  //     } else if (line) {
  //       if (currentSection) {
  //         currentSection.subPoints.push(line); // Treat as sub-point in the current section
  //       } else if (introText) {
  //         currentSection = { title: introText, subPoints: [line] };
  //         introText = '';
  //       } else {
  //         currentSection = { title: '', subPoints: [line] };
  //       }
  //     }
  //   });
  
  //   if (currentSection) {
  //     sections.push(currentSection);
  //   } else if (introText) {
  //     sections.push({ title: introText, subPoints: [] }); // Add introText as a section if there are no bullet points
  //   }
  
  //   return { introText, sections };
  // };

  // const renderListItems = (sections, level = 0) => {
  //   const itemStyle = level === 0 ? styles.listItem : styles.nestedListItem;
  //   return sections.map((section, index) => (
  //     <View key={index} style={itemStyle}>
  //       <Text style={[styles.subtitle2, styles.textMargin]}>{level === 0 ? `• ${section.title}` : `  o ${section.title}`}</Text>
  //       {section.subPoints && section.subPoints.map((point, subIndex) => (
  //         <View key={subIndex} style={styles.nestedListItem}>
  //           <Text style={[styles.subtitle2, styles.textMargim, styles.rightMargin]}> o {point}</Text>
  //         </View>
  //       ))}
  //     </View>
  //   ));
  // };

  const processString = (input) => {
    // Replace tab spaces with a visual indicator or extra spaces (e.g., '    ' for tab)
    return input
    .replace(/\t/g, '    ') 
    .replace(/●/g, '•')
    .replace(/▪️/g, '•'); 
    // Replace each tab with 4 spaces
  };

  // const lines = string.split('\n');

  
  return (
    <Document >
      <Page size="A4" style={styles.page} orientation='landscape'>
      {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col2}>
                      <Image source={logo} style={{ width: 80, height: 80 }} />
                  </View>
                  <View style={styles.col3}>
                    <Text style={styles.h4}>{companyProfile?.companyName}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                </View>
                </View>
            </View>
          :
          ""
        }
      <View style={styles.col7}><Text style={styles.h4}>Assessment Record Form</Text></View>
            
          <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Course Title: ${course?.courseTitle}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`TGS Ref No: ${course?.tsgRefNumber}`}</Text>
              </View>
           </View>

           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Course Skill Code:  ${course?.courseSkillCode}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Candidate NRIC: ${nricFin}`}</Text>
              </View>
           </View>

           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Candidate Name: ${fullName}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Assessment Date: ${assessmentSummary?.createdDate ? fDate(assessmentSummary?.createdDate) : 'NA'}`}</Text>
              </View>
           </View>

           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Assessor Name: ${assForm?.createdBy}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
              <Text style={[styles.subtitle2, styles.textMargin]}>{`Assessment Duration: ${SSGTime(assessmentSummary?.assessmentStartTime)} - ${SSGTime(assessmentSummary?.assessmentEndTime)}`}</Text>
              </View>
           </View>
       
          </View>
 

        {/* TABLE START HERE */}
        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>

              <View style={[styles.tableCell_1, styles.greenColor]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>Assessment Criteria</Text>
              </View>

              <View style={[styles.tableCell_2, styles.greenColor]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>Result</Text>
              </View>

              <View style={[styles.tableCell_3, styles.greenColor]}>
                <Text  style={[styles.subtitle2, styles.textMargin]}>Assessor Remark</Text>
              </View>
            </View>
          </View>

          <View>
            {assessmentRecordForm?.map((item, index) => (
               <View>
                    <View style={styles.tableRow} key={item.id}>
                          <View style={[styles.tableCell_1, styles.grayColor]}>
                            <Text style={[styles.subtitle2, styles.textMargin]}>{item?.assessmentMethod}</Text>
                          </View>
                    </View>
                  {item?.assessmentDetails?.map((res, i) => (
                    <>
                    <View style={styles.tableRow} key={item.id}>
                          <View style={[styles.tableCell_1, styles.whiteColor]}>
                              <Text style={[styles.subtitle2, styles.textMargin]}>{res?.technicalSkills}</Text>
                          </View>
                    </View>
          
                    {res?.assessmentCriteria?.map((ac, acind) => {
                              // const formattedHtmlString = formatHtmlString(ac?.assessmentCriteria)
                             // const { introText, sections } = parseText(ac?.assessmentCriteria);
                              const aCriteria = processString(ac?.assessmentCriteria);

                              return (
                                <View style={styles.tableRow} key={item.id}>
                                <View style={styles.tableCell_1}>
                                <Text style={[styles.subtitle2, styles.textMargin]}>{aCriteria}</Text> 

                                    {/* <Text style={[styles.subtitle2, styles.textMargin]}>{introText}</Text> */}
                                    {/* <Text style={[styles.subtitle2, styles.textMargin]}>{ac?.assessmentCriteria}</Text> */}
                                      {/* {renderListItems(sections)} */}
                                    {/* {sections.map((section, index) => (
                                    <View key={index} style={{ marginBottom: 10 }}>
                                      <Text style={{ marginBottom: 5 }}>• {section.title}</Text>
                                      <View style={{ marginLeft: 15 }}>
                                        {section.subPoints.map((subPoint, subIndex) => (
                                          <Text key={subIndex} style={{ marginBottom: 5 }}>
                                            o {subPoint}
                                          </Text>
                                        ))}
                                      </View>
                                    </View>
                                  ))} */}

                               </View>
                               <View style={[styles.tableCell_2, styles.resultRemark]}>
                                   <Text style={[styles.subtitle2, styles.textMargin]}>{ac?.criteriaResults}</Text>
                               </View>
                               <View style={[styles.tableCell_2, styles.resultRemark]}>
                                   <Text style={[styles.subtitle2, styles.textMargin]}>{ac?.criteriaRemarks}</Text>
                               </View>
                            </View>
                              )
          
                    })}
                               
                    </>
    
                  ))}

               </View>
          ))}
            </View>
          <View>


          </View>

        </View>

                           {/* TABLE END HERE */}
      </Page>
    </Document>
  );
}

AssessmentFormPDF.propTypes = {
  traineeInfo: PropTypes.node,
  assForm: PropTypes.node,
  classinfo: PropTypes.node
};

import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Box, Dialog, Stack } from '@mui/material';
// utils
import { fDate, fTime } from '../../../../util/formatTime.js';
import { fCurrency } from '../../../../util/format-number.js';
import { text } from 'body-parser';

// ----------------------------------------------------------------------

Font.register({
  family: 'Ubuntu',
  fontStyle: "normal",
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col0: { width: '5%' },
        col1: { width: '10%' },
        col2: { width: '20%' },
        colx: { width: '30%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        col7: { width: '100%' },
        col9: { width: '70%' },
        mt1: { marginTop: 2 },
        p1: { paddingTop: 11 },
        mtop4: {marginTop: 8},
        mb8: { marginBottom: 8 },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body_1: { fontSize: 10, textTransform: 'lowercase' },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 12, fontWeight: 800},
        subtitle1_1: { fontSize: 12, fontWeight: 800, textTransform: 'lowercase'},
        subtitle2: { fontSize: 10, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        textMargin: {margin: 2},
        textMargin_1: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_x1: {
          margin: 1,
          textAlign: 'left'
        },
        textMargin_2: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_3: {
          margin: 1
        },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 40px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRowHead: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          color: "#ffffff",
          backgroundColor: "gray",
          borderColor: '#000000',
        },
        tableRowTop: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          color: "#ffffff",
          backgroundColor: "green",
          borderColor: '#000000',
        },
        tableRowTrainee: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRow1: {
          flexDirection: 'row',
        },
        
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
        },
        tableCell_2: {
          width: '30%'
        },
        tableCell_3: {
          width: '30%',
        },
        tableCell_4: {
          width: '70%',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultAtt: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader1: {
          backgroundColor: "#ffffff"
        },
        textTrans: { fontSize: 10, textTransform: 'none' },
          headerWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 50, // Adjust this based on your header height
    flexDirection: 'row',
  },
      }),
    []
);

// ----------------------------------------------------------------------

export default function AttendancePDF({ attendance, companyProfile, attritionRate }) {
  const styles = useStyles();
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;
  const trainee = attendance?.enrolment;
  const course = attendance?.course;
  const courseDate = attendance?.courseDates;
  const session  = attendance?.sessions;
//   const nricFin = res?.nricFin ? res?.nricFin.slice(0, 1) + '*'.repeat(res?.nricFin?.length -4) + res?.nricFin?.slice(-4) : "";

let column = [];
let continuousIndex = 0;  // Initialize continuous index
// const filteredSessions = session.filter(item => item.modeOfTraining !== "8");

// const uniqueTrainers = session.filter((item, index, self) =>
//   index === self.findIndex((t) => t.trainer.nricFin === item.trainer.nricFin)
// );
const setTrainer = session.map(item => item?.trainer?.fullName)
const filterTrainer = [...new Set(setTrainer)];

for (let i = 0; i < session.length; i += 4) {
  let group = session.slice(i, i + 4);
  while (group.length < 4) {
    group.push(null);  // Fill with null if group has fewer than 4 items
  }
  // Add continuous index to each session item
  const indexedGroup = group.map(item => ({
    item,
    index: continuousIndex++  // Increment index continuously
  }));
  column.push(indexedGroup);
}



const Header = () => (
    <>
        {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col2}>
                      <Image source={logo} style={{ width: 80, height: 80 }} />
                  </View>
                  <View style={styles.col3}>
                    <Text style={styles.h4}>{companyProfile?.companyName}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                </View>
                </View>
            </View>
          :
          ""
        }
   
        <View style={[styles.table, styles.mb8]}>
            <View style={[styles.tableRow, { fixed: true }]}>
              <View style={[styles.tableCell_1, styles.resultAtt]}>
                <Text style={[styles.body2, styles.textMargin]}>{`Class Title: ${course?.courseTitle}`}</Text>
                </View>

                <View style={[styles.tableCell_1, styles.resultAtt]}>
                <Text style={[styles.body2, styles.textMargin]}>{`Class Code: ${attendance?.classCode}`}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1, styles.titleheader, styles.resultAtt]}>
                <Text style={[styles.body2, styles.textMargin]}>{`Training Start Date: ${fDate(courseDate?.start)}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader, styles.resultAtt]}>
                <Text style={[styles.body2, styles.textMargin]}>{`Training End Date: ${fDate(courseDate?.end)}`}</Text>
              </View>
           </View>
        </View>
        </>
)


const Footer = () => (
  <>
     <View style={[styles.table, styles.mb8]}>
      <View style={styles.tableRow}>
       <View style={[styles.tableCell_1, styles.resultAtt]}>
          <Text style={styles.h4}>{`Trainer Name: ${filterTrainer.join(", ")}`}</Text>
          <Text style={styles.h4}>{`Trainer Sign:`}</Text>
      </View>
      <View style={[styles.tableCell_1, styles.resultAtt]}>
          <Text style={[styles.h4, styles.mt1]}>{`Attrition  Rate: ${attritionRate}`}</Text>
      </View>

     </View>
     </View>
  </>
)

  return (
    <Document>
        {
          column?.map((results, index ) => (
            <Page size="A4" style={styles.page} orientation='landscape' key={index}>
                  {index === 0 && <Header />}
                  <View style={styles.table}>
                    <View style={styles.tableRowTop}>
                    <View style={[styles.col2, styles.resultAtt]}>
                       <Text style={[styles.body2, styles.textMargin_2]}>S/N</Text>
                      </View>
                      <View style={[styles.tableCell_4, styles.resultAtt]}>
                       <Text style={[styles.body2, styles.textMargin_2]}>Fullname</Text>
                      </View>
                      <View style={[styles.tableCell_4, styles.resultAtt]}>
                       <Text style={[styles.body2, styles.textMargin_2]}>NRIC</Text>
                      </View>
                      <View style={[styles.tableCell_4, styles.resultAtt]}>
                       <Text style={[styles.body2, styles.textMargin_2]}>Company Name</Text>
                      </View>
                      {
                        results?.map((res, i) => (
                          <View style={[styles.tableCell_4, styles.resultAtt]}>
                          <Text style={[styles.body2, styles.textMargin_1]}>{res?.item?.startDate  ? `${fDate(res?.item?.startDate)}` : ""}</Text>
                        </View>
                        ))}
                    </View>
                    <View style={styles.tableRowHead}>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_1]}>{`${index + 1}`}</Text>
                           </View>
                           <View style={[styles.tableCell_4, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_1]}>{` `}</Text>
                           </View>
                           <View style={[styles.tableCell_4, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_1]}>{` `}</Text>
                           </View>
                           <View style={[styles.tableCell_4, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_1]}>{` `}</Text>
                           </View>
                      {
                        results?.map((res, i) => (
                          <>
                            <View style={[styles.tableCell_4, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_1]}>{ res?.item?.startDate ? `${fTime(res?.item?.startTime)} - ${fTime(res?.item?.endTime)}` : ""}</Text>
                            </View>
                     
                          </>
                        ))
                      }
  
                   </View>
                   
                    <View>
                      {
                        trainee.map((tr, trIndex) => (
                          <View style={styles.tableRowTrainee}>
                          <View style={[styles.col2, styles.resultAtt]}>
                            <Text style={[styles.body2, styles.textMargin_2]}>{trIndex + 1}</Text>
                          </View>
                          <View style={[styles.tableCell_4, styles.resultAtt]}>
                            <Text style={[styles.body2, styles.textMargin]}>{tr?.traineeInfo?.fullName}</Text>
                          </View>
                          <View style={[styles.tableCell_4, styles.resultAtt]}>
                            <Text style={[styles.body2, styles.textMargin]}>{tr?.traineeInfo?.nricFin ? tr?.traineeInfo?.nricFin.slice(0, 1) + '*'.repeat(tr?.traineeInfo?.nricFin?.length -4) + tr?.traineeInfo?.nricFin?.slice(-4) : ""}</Text>
                          </View>
                          <View style={[styles.tableCell_4, styles.resultAtt]}>
                            <Text style={[styles.body2, styles.textMargin]}>{tr?.employer?.empcompanyName ? tr?.employer?.empcompanyName : ""}</Text>
                          </View>

                          {
                            
                              results?.map((res, i) => 
                                {
                                  const signSession = tr?.eAttendance ? tr?.eAttendance[i] : "NA";
                                  // console.log(signSession)
                                  return (
                                      <View style={[styles.tableCell_4, styles.resultAtt]}>
                                        {
                                          res?.item?.startDate ? 
                                           i === signSession?.eSignature?.sessionNo ?
                                           <Image source={ signSession?.eSignature?.eSign} style={{ width: 58, height: 58 }} />
                                          //  <Text style={[styles.body2, styles.textMargin_1]}>{signSession?.eSignature?.sessionNo}</Text>
               
                                            :
                                            <Text style={[styles.body2, styles.textMargin_1]}>{``}</Text>
                                          :
                                          <Text style={[styles.body2, styles.textMargin_1]}>{``}</Text>
                                        }
                                      </View>
                                  )
                                  // if(i === signSession?.eSignature?.sessionRow?.sessionNo)
                                  // if(i === signSession?.eSignature?.sessionNo){
            
                                  // }else {
                                  //   <View style={[styles.tableCell_4, styles.resultAtt]}>
                                  //      <Text style={[styles.body2, styles.textMargin_1]}>{` `}</Text>
                                  //   </View>
                                  // }

                                })
                            }

                        </View>
                        ))
                      }
                   </View>
                  </View>

                  {index === column.length - 1 && <Footer />}
            </Page>
          ))
        }

    </Document>

  );
}

AttendancePDF.propTypes = {
  attendance: PropTypes.object,
  companyProfile: PropTypes.object
}
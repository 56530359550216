import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Stack, Button, Checkbox, TextField, Tooltip, IconButton, FormControl, OutlinedInput, InputAdornment, Select } from '@mui/material';
import Iconify from '../../../../components/iconify/index.js';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';


export default function InvoiceTableToolBar({
    filterInvoice,
    onFilters,
    onFilterReset,
    onExportCSV
}){
    // const popover = usePopover();
    
    const handleFilterName = useCallback((event) => {
        onFilters('name', event.target.value)
    }, [onFilters])

    const handleDateFrom = useCallback((event) =>{
        onFilters('dateFrom', event)
    },[onFilters])

    const handleDateTo = useCallback((event) =>{
        onFilters('dateTo', event)
    },[onFilters])

    // const handleFilterReset = () =>{
    //     onFilters('searchText.name', "")
    //     onFilterReset();
    // }

    

    return (
        <>
            <Stack
                spacing={2}
                alignItems={{xs: 'flex-end', md: 'center'}}
                direction={{
                    xs: 'column',
                    md: 'row'
                }}
                sx={{
                    p: 2.5,
                    pr: {xs: 2.5, md: 1}
                }}
            >

                <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1}} >
                    <TextField
                    fullWidth
                    value={filterInvoice?.name}
                    onChange={handleFilterName}
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}} />
                            </InputAdornment>
                        )
                    }}
                    />

                <DatePicker
                  label="Course Start Date From"
                  format='dd/MM/yyyy'
                  onChange={handleDateFrom}
                  value={filterInvoice?.dateFrom}
                  slotProps={{
                    textField: {
                      fullWidth: true
                    },
                  }}
                  sx={{width: 410}}
                  renderInput={(params) => <TextField {...params} />}
                />

                    <DatePicker
                  label="Course Start Date To"
                  format='dd/MM/yyyy'
                  onChange={handleDateTo}
                  value={filterInvoice?.dateTo}
                  slotProps={{
                    textField: {
                      
                    }
                  }}
                  sx={{width: 410}}
                  renderInput={(params) => <TextField {...params} />}
                />
                 <Tooltip title="Reset Filter">
                <IconButton color="error" onClick={onFilterReset} sx={{ width: 50, height: 50 }}>
                    <Iconify icon="bx:reset" />
                </IconButton>
                </Tooltip>
                
                <Tooltip title="Export CSV">
                <IconButton color="secondary" onClick={onExportCSV} sx={{ width: 50, height: 50 }}>
                    <Iconify icon="hugeicons:csv-02" />
                </IconButton>
                </Tooltip>
            
                </Stack>
         

            </Stack>
        </>
    )
}

InvoiceTableToolBar.propTypes = {
    filterInvoice: PropTypes.object,
    onFilters: PropTypes.func,
    onFilterReset: PropTypes.func,
    onExportCSV: PropTypes.func,
    subsOptions: PropTypes.array
}
import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Box, Dialog, Stack } from '@mui/material';
// utils
import { fDate, fTime } from '../../../../util/formatTime.js';
import { fCurrency } from '../../../../util/format-number.js';
import { text } from 'body-parser';

// ----------------------------------------------------------------------

Font.register({
  family: 'Ubuntu',
  fontStyle: "normal",
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col0: { width: '5%' },
        col1: { width: '10%' },
        col2: { width: '20%' },
        colx: { width: '30%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        col7: { width: '100%' },
        col9: { width: '70%' },
        mt1: { marginTop: 2 },
        p1: { paddingTop: 11 },
        mtop4: {marginTop: 8},
        mb8: { marginBottom: 8 },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body_1: { fontSize: 10, textTransform: 'lowercase' },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 12, fontWeight: 800},
        subtitle1_1: { fontSize: 12, fontWeight: 800, textTransform: 'lowercase'},
        titleHeader: {fontFamily: 'Helvetica-Bold', fontWeight: 900, fontSize: 12},
        subtitle2: { fontSize: 10, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        textMargin: {margin: 2},
        textMarginheader: {
          marginLeft: 1
        },
        textMargin_1: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_x1: {
          margin: 1,
          textAlign: 'left'
        },
        textMargin_2: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_3: {
          margin: 1
        },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          padding: '40px 24px 40px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRowHead: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          color: "#ffffff",
          borderColor: '#000000',
        },

        tableRowTop: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          color: "#ffffff",
          backgroundColor: "green",
          borderColor: '#000000',
        },

        
        tableRow1: {
          flexDirection: 'row',
        },
        
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
        },
        tableCell_2: {
          width: '30%'
        },
        tableCell_3: {
          width: '30%',
        },
        tableCell_4: {
          width: '70%',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultAtt: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader1: {
          backgroundColor: "#ffffff"
        },
        textTrans: { fontSize: 10, textTransform: 'none' },
      }),
    []
);

// ----------------------------------------------------------------------

export default function AssignedPDF({ assigned, companyProfile, searchText }) {
  const styles = useStyles();
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;
  const trainee = assigned?.enrolment;
  const course = assigned?.course;
  const courseDate = assigned?.courseDates;
  // const session  = assigned?.sessions;
//   const nricFin = res?.nricFin ? res?.nricFin.slice(0, 1) + '*'.repeat(res?.nricFin?.length -4) + res?.nricFin?.slice(-4) : "";
const trainerAssessor = assigned ? assigned[0]?.course?.trainerAssessor?.find(tr => tr?.fullName?.toLowerCase().includes(searchText?.toLowerCase()) || tr?.nricFin === searchText?.toLowerCase() ) : null;
const sessions = assigned?.map((item) => item?.sessions);


const filterAndCount = (arr, name) => {
  // Filter the array by the specified name
  const filtered = arr?.filter(ass => ass?.assessmentSummary?.criteriaResults === name);

  // Count occurrences of the filtered names
  return filtered.reduce((acc, res) => {
    const { criteriaResults } = res?.assessmentSummary;
    acc[criteriaResults] = (acc[criteriaResults] || 0) + 1;
    return acc;
  }, {});
};




console.log(assigned)

  return (
 
    <Document>
      <Page size="A4" style={styles.page} orientation='landscape'>
        {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col2}>
                      <Image source={logo} style={{ width: 80, height: 80 }} />
                  </View>
                  <View style={styles.col3}>
                    <Text style={styles.titleHeader}>{companyProfile?.companyName}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                </View>
                </View>
            </View>
          :
          ""
        }
            <Text style={[styles.titleHeader, styles.textMargin]}>{`Class Assignment Report`}</Text>
          <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.body2, styles.textMarginheader]}>{`Trainer/Assessor: ${trainerAssessor?.fullName}`}</Text>
                <Text style={[styles.body2, styles.textMarginheader]}>{`Email: ${trainerAssessor?.email}`}</Text>
                <Text style={[styles.body2, styles.textMarginheader]}>{`Contact No: ${trainerAssessor?.mobileNo}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.body2, styles.textMarginheader]}>{`Report Generated Date: ${fDate(new Date())}`}</Text>
              </View>
           </View>
           </View>


           <View style={styles.table}>
            <View>
              <View style={styles.tableRowTop}>
                <View style={[styles.tableCell_4, styles.col0, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_2]}>S/N</Text>
                </View>
                
                <View style={[styles.tableCell_4, styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Course Run Id</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col4, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Course Title</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Class Code</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>isTrainer?</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>isAssessor?</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>No of Trainee</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Passing Rate</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Start Date</Text>
                </View>
                <View style={[styles.tableCell_4, styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>End Date</Text>
                </View>
              </View>
            </View>
           

                {assigned?.map((res, i) => {
                    const confirmedCount = res?.courseRunId ? res?.enrolment?.filter((item) => item?.ssgenrolment?.status === "Confirmed").length : res?.enrolment?.filter((item) => item?.ssgenrolment?.status === "Draft").length;
                    const hasMode8ForD =  res?.sessions?.some(
                       (entry) => entry.trainer.fullName?.toLowerCase() === searchText?.toLowerCase() && entry.modeOfTraining === "8"
                      )
                      const hasMode1ForD =  res?.sessions?.some(
                        (entry) => entry.trainer.fullName?.toLowerCase() === searchText?.toLowerCase() && entry.modeOfTraining !== "8"
                      )

                      const passCount = filterAndCount(res?.enrolment, 'Pass');
                      const failCount = filterAndCount(res?.enrolment, 'Fail');
                      const passRate = (passCount?.Pass / res?.enrolment?.length ) * 100;


                  return(
                    <View style={styles.tableRow}>
                    <View style={[styles.tableCell_4, styles.col0, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{`${i + 1}`}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col1, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{res?.courseRunId}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col4, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{res?.course?.courseTitle}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col2, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{res?.classCode}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col1, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{hasMode1ForD ? "Yes" : "No"}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col1, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{hasMode8ForD ? "Yes" : "No"}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col1, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{confirmedCount}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col1, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin]}>{passRate ? passRate?.toFixed(2) + '%' : '0%'}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col1, styles.resultAtt]}>
                    <Text style={[styles.body2, styles.textMargin]}>{`${fDate(res?.courseDates?.start)}`}</Text>
                    </View>
                    <View style={[styles.tableCell_4,  styles.col1, styles.resultAtt]}>
                    <Text style={[styles.body2, styles.textMargin]}>{`${fDate(res?.courseDates?.end)}`}</Text>
                    </View>
               </View>
                  )
                })}
           
            <View>

    
                    {/* <View style={styles.tableRow}>
                            <View style={styles.tableCell_4}>
                            <Text style={[styles.subtitle1, styles.textMargin]}>{`Trainee:`}</Text>
                              <>
                              {items?.map((res, i) => {
                                  const nricFin = res?.nricFin ? res?.nricFin.slice(0, 1) + '*'.repeat(res?.nricFin?.length -4) + res?.nricFin?.slice(-4) : "";
                                return (
                                  <Text style={[styles.subtitle1, styles.textMargin]}>{`${res?.description} ${nricFin}`}</Text>
                                )
                              })}
                               </>
                               <>
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{`Course Title: ${courseInfo?.courseTitle}`}</Text>
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{courseInfo?.tsgRefNumber}</Text>
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{`Training Dates: ${courseInfo?.courseStart} - ${courseInfo.courseEnd}`}</Text>
                               </>
                               <>
                              {grants?.map((res, i) => (
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{`Less ${res?.schemeName}`}</Text>
                               ))}
                                <Text style={[styles.subtitle1, styles.textMargin]}>{`Less Course fee discount:`}</Text>
                               </>
                           </View>
                           <View style={[styles.tableCell_2, styles.resultRemark]}>
                              <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                              <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                               {items?.map((res, i) => (
                               <Text style={[styles.subtitle1, styles.textMargin_1]}>{fCurrency(res?.price)}</Text>
                               ))}
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1, styles.p1]}>{totalGrants !== 0 ? `-${fCurrency(allGrants)}` : ''}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{discount !==0 ? `-${fCurrency(discount)}` : ' '}</Text>
                           </View>
                     </View>

                     <View style={[styles.table, styles.mb8]}>
                        <View style={[styles.tableRow]}>
                              <View style={[styles.col9, styles.mt1]}>
                                  <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`Sub-Total:`}</Text>
                                    <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`GST ${taxes}%:`}</Text>
                                    <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`Balance Payable:`}</Text>
                                  </View>
                                <View style={[styles.colx, styles.mt1]}>
                                      <Text style={[styles.subtitle1, styles.textMargin_1]}>{`${fCurrency(subTotal)}`}</Text>
                                      <Text style={[styles.subtitle1, styles.textMargin_1]}>{taxes !==0 ? `${fCurrency(tax)}` : ' '}</Text>
                                      <Text style={[styles.subtitle1, styles.textMargin_1]}>{`${fCurrency(totalAmount)}`}</Text>
                                </View>
                        </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={styles.col7}>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>Payment Methods:</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{`(i) By PayNow - UEN ${companyProfile?.uen}`}</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{`(ii) By Bank Transfer - ${companyProfile?.bankName} A/C  #${companyProfile?.accountNo}. A/C Name ${companyProfile?.accountName}`}</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{invoiceToInfo?.empcompanyName ? '' : `(iii) By SkillsFuture Credit`}</Text>
                            </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={[styles.tableCell_4, styles.mtop4]}>
                            <Text style={[styles.subtitle2, styles.textMargin_3]}>Payment Instructions:</Text>
                            <Text style={[styles.subtitle2, styles.textMargin_3,  styles.textTrans]}>{`${companyProfile?.paymentInstruction}`}</Text>
                            </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={[styles.tableCell_4, styles.mtop4]}>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>Terms & Conditions:</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3, styles.textTrans]}>{`${companyProfile?.termsAndCondition}`}</Text>
                            </View>
                    </View> */}
    
               
            </View>
          </View>
  


      </Page>
    </Document>

  );
}

AssignedPDF.propTypes = {
  attendance: PropTypes.object,
  companyProfile: PropTypes.object
}
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material';
import { logoutUsers } from "../../../store/modules/index.js"
import { useBoolean } from '../../../hooks/use-boolean.js';
import AnnouncementDialog from './announcement.js';
import EmailSettingsDialog from './emailSettings.js';

import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  // },
  {
    label: 'Announcement',
    icon: 'mingcute:announcement-line',
  },
  {
    label: 'Email Settings',
    icon: 'mingcute:announcement-line',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  }
];

// ----------------------------------------------------------------------

export default function Account() {
  const fullName = useSelector((state) => state?.auth?.user?.fullName);
  const email = useSelector((state) => state?.auth?.user?.email);
  const isRole = useSelector((state) => state?.auth?.isRole);
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const announceDialog = useBoolean();
  const emailSettings = useBoolean()


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = (event) => {
    setOpen(false);
  };

  const handleNavigate = (label) => {
    if(label === 'Settings'){
      navigate(`/${companyProfile?.databaseName}/company-update-info`, {state: companyProfile})
      setOpen(false);
    }else if (label === 'Announcement'){
      announceDialog.onTrue();
    }else if (label === 'Email Settings'){
      emailSettings.onTrue();
    }
  };

  const handleLogOut = () => {
    dispatch(logoutUsers());
  };

  const handleCloseDialog = ((id) => {
    announceDialog.onFalse();
    window.location.reload();
  })

  const handleCloseEmailDialog = ((id) => {
    announceDialog.onFalse();
    window.location.reload();
  })

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src="/assets/images/avatars/avatar.jpg" alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {
          isRole?.some((role)=> ["bo"].includes(role)) ?
          <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleNavigate(option.label)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
          :
          ""

        }


        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>

        <AnnouncementDialog 
          open={announceDialog.value}
          onClose={handleCloseDialog}
        />
        <EmailSettingsDialog 
          open={emailSettings.value}
          onClose={handleCloseEmailDialog}
        />


      </Popover>
    </>
  );
}
const _invoiceStatus = [
    {value: 'paid', label: 'Paid'},
    {value: 'pending', label: 'Pending'},
    {value: 'overdue', label: 'Overdue'},
    {value: 'draft', label: 'Draft'},
    {value: 'cancelled', label: 'Cancelled'},
  ]

  const _receiptStatus = [
    {value: 'No Payment', label: 'No Payment'},
    {value: 'Partial Payment', label: 'Partial Payment'},
    {value: 'Full Payment', label: 'Full Payment'}
  ]
export {
    _invoiceStatus,
    _receiptStatus
  }
import gql from "graphql-tag";

export const GET_CLASSES = gql`
query GetClasses($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String, $filterBy: String, $isRole: JSONObject) {
    getClasses(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy, filterBy: $filterBy, isRole: $isRole) {
        code
        success
        message
        results
        count
        confirmed
        cancelled
        completed
        draft
        
  }
}`;


export const GET_CLASS_BY_ID = gql`
query GetClassById($_id: ID!, $companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
  getClassesById(_id: $_id, companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        results
        count
        confirmed
        cancelled
        draft
  }
}`;

export const GET_ENROLMENT_CLASS_ID = gql`
query GetEnrolmentClassId($_id: ID!, $companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
  getEnrolmentClassId(_id: $_id, companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        results
        count
        confirmed
        cancelled
        
  }
}`;





export const CREATE_CLASSES = gql`
mutation CreateClass($data: CourseInput!, $fullName: String!, $companyName: String!, $uen: String!, $encryptionkey: String!, $_companyName: String!) {
  createClass(data: $data, fullName: $fullName, companyName: $companyName, uen: $uen, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;

export const CREATE_CLASS_AS_DRAFT = gql`
mutation CreateClassAsDraft($data: CourseInput!, $fullName: String!, $companyName: String!) {
  createClassAsDraft(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;



export const UPDATE_CLASSES = gql`
mutation UpdateClass($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!, $uen: String!, $encryptionkey: String!, $_companyName: String!) {
  updateClass( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, uen: $uen, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_CONFIRM_SUBMIT = gql`
mutation UpdateConfirmSubmit($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!, $uen: String!, $encryptionkey: String!, $_companyName: String!) {
  updateConfirmSubmit( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, uen: $uen, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;



export const UPDATE_CLASSES_AS_DRAFT = gql`
mutation UpdateClassAsDraft($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!) {
  updateClassAsDraft( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_CLASS_ASS_FORM = gql`
mutation UpdateClassAssForm($_id: ID!, $data: JSONObject!, $fullName: String!, $companyName: String!) {
  updateClassAssForm( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;





export const DELETE_CLASSES= gql`
mutation DeleteClass($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteClass(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const CANCELLED_CLASS= gql`
mutation CancelledClass($_id: ID!, $fullName: String!, $companyName: String!, $courseReferenceNumber: String!, $courseRunId: String, $status: String!, $uen: String!, $encryptionkey: String!, $_companyName: String!) {
  cancelledClass(_id: $_id, fullName: $fullName, companyName: $companyName, courseReferenceNumber: $courseReferenceNumber, courseRunId: $courseRunId, status: $status, uen: $uen, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;

export const COMPLETED_CLASS= gql`
mutation CompletedClass($_id: ID!, $fullName: String!, $companyName: String!) {
  completedClass(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const REVERT_CLASS= gql`
mutation RevertClass($_id: ID!, $fullName: String!, $companyName: String!) {
  revertClass(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const CREATE_ENROLMENT = gql`
mutation CreateEnrolment($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!, $encryptionkey: String!, $_companyName: String!) {
  createEnrolment( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;

export const CANCEL_ENROLMENT = gql`
mutation CancelEnrolment($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!, $referenceNumber: String!, $encryptionkey: String!, $_companyName: String!) {
  cancelEnrolment( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, referenceNumber: $referenceNumber, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_ENROLMENT = gql`
mutation UpdateEnrolment($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!, $referenceNumber: String!, $encryptionkey: String!, $_companyName: String!) {
  updateEnrolment( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, referenceNumber: $referenceNumber, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;


export const GET_COURSE_RUN_ID = gql`
query GetCourseRunId($uen: String, $courseReferenceNumber: String, $runid: String, $encryptionkey: String!, $_companyName: String!) {
  getCourseRunId(uen: $uen, courseReferenceNumber: $courseReferenceNumber, runid: $runid, encryptionkey: $encryptionkey, _companyName: $_companyName) {
        code
        success
        results
  }
}`;

export const CREATE_ENROLMENT_DRAFT = gql`
mutation CreateEnrolmentDraft($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!) {
  createEnrolmentDraft( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;


export const SUBMIT_ENROLMENT_DRAFT_STATUS = gql`
mutation SubmitEnrolment($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!, $encryptionkey: String!, $_companyName: String!) {
  submitEnrolment( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;

export const REMOVE_TRAINEE_IN_ENROLMENT = gql`
mutation DeleteTraineeEnrolment($_id: ID!, $traineeID: ID!, $fullName: String!, $companyName: String!, $enrolmentID: String!) {
  deleteTraineeEnrolment(_id: $_id, traineeID: $traineeID, fullName: $fullName, companyName: $companyName, enrolmentID: $enrolmentID) {
    code
    success
    message
  }
}`;


export const UPDATE_CLASS_SESSION = gql`
mutation UpdateClassSession($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!, $uen: String!, $encryptionkey: String!, $_companyName: String!, $sessionIndex: Int) {
  updateClassSession( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, uen: $uen, encryptionkey: $encryptionkey, _companyName: $_companyName, sessionIndex: $sessionIndex) {
    code
    success
    message
  }
}`;

export const GET_ASSIGNED_TA_CLASS = gql`
query GetAssignedTAClass($companyName: String!, $limit: Int, $offset: Int, $searchText: JSONObject, $order: String, $orderBy: String, $filterBy: String, $isRole: JSONObject) {
  getAssignedTAClass(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy, filterBy: $filterBy, isRole: $isRole) {
      code
      success
      message
      results
      count
      confirmed
      cancelled
      completed
      draft
}
}`;








import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { useLocation } from 'react-router-dom';
import { Stack, Dialog, DialogContent, DialogTitle, DialogActions, Box, MenuItem} from '@mui/material';

import {LoadingButton} from '@mui/lab';
// import {_paymentStatus} from '../../../util/course.js';
import { useSnackbar } from '../../../components/snackbar/index.js';
import FormProvider, {
    RHFTextField,
    RHFSelect
  } from '../../../components/hook-form/index.js';

import {GET_EMAIL_CREDENTIALS, CREATE_EMAIL_CRED, UPDATE_EMAIL_CRED} from 'src/graphql/modules/_client/sendemail.js';
import { GET_ANNOUNCEMENT, CREATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from "../../../graphql/modules/_client/announcement.js";

import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";

const service = [
    {value: "yahoo", label: "yahoo"},
    {value: "gmail", label: "gmail"},
    {value: "customMail", label: "customMail"},
    {value: "microsoft", label: "microsoft"}
]


// ----------------------------------------------------------------------

export default function EmailSettingsDialog({open, onClose}) {
    const fullName = useSelector((state) => state?.auth?.user?.fullName);
    const company = useSelector((state) => state?.auth?.company);
    const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
    const [isLoading, setIsLoading] = useState(false)
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [emailCredential, setEmailCred] = useState(null);
    const [CreateEmailMutation] =  useMutation(CREATE_EMAIL_CRED);
    const [UpdateEmailMutation] =  useMutation(UPDATE_EMAIL_CRED);
    

    const newEmail = Yup.object().shape({
        clientid: Yup.string().required('Client ID is required, please use NA'),
        clientsecret: Yup.string().required('Client Secret is required'),
        redirecturl: Yup.string().required('Redirect URL is required'),
        host: Yup.string().required('Host is required'),
        port: Yup.string().required('Port is required'),
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('App Password or Password is required'),
        service: Yup.string().required('Service is required')
      });
    

    const defaultValues = {
      clientid: emailCredential?.CLIENT_ID || '' ,
      clientsecret: emailCredential?.CLIENT_SECRET || '',
      redirecturl: emailCredential?.REDIRECT_URI || '',
      refreshtoken: emailCredential?.REFRESH_TOKEN || '',
      host: emailCredential?.Host || '',
      port: emailCredential?.Port || 456,
      email: emailCredential?.Email || '',
      password: emailCredential?.Password || '',
      service: emailCredential?.Service || ''
    };

    const { data: emailCred  } = useQuery(GET_EMAIL_CREDENTIALS, {
        variables: {
          companyName: company || ''
          },
          fetchPolicy: "cache-and-network",
    });
    
      const methods = useForm({
        resolver: yupResolver(newEmail),
        defaultValues,
      });
    
      const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
      } = methods;

      const onSubmit = handleSubmit(async (data) => {
        try {
            if(emailCredential){
                const {data: { updateEmailCredentials }} = await UpdateEmailMutation({
                    variables: {
                      companyName: company,
                      id: emailCredential?._id,
                        data: {
                          CLIENT_ID: data?.clientid,
                          CLIENT_SECRET: data?.clientsecret,
                          REDIRECT_URI: data?.redirecturl,
                          REFRESH_TOKEN: data?.refreshtoken,
                          Service: data?.service,
                          Email: data?.email,
                          Password: data?.password,
                          Host: data?.host,
                          Port: data?.port
                        }
                      }
                  });
                  if(updateEmailCredentials?.success){
                    onClose();
                  }else {
                    enqueueSnackbar("Error", {variant: "error"});
                  }
            }else {
                const {data: { addEmailCredentials }} = await CreateEmailMutation({
                    variables: {
                      companyName: company,
                        data: {
                          CLIENT_ID: data?.clientid,
                          CLIENT_SECRET: data?.clientsecret,
                          REDIRECT_URI: data?.redirecturl,
                          REFRESH_TOKEN: data?.refreshtoken,
                          Service: data?.service,
                          Email: data?.email,
                          Password: data?.password,
                          Host: data?.host,
                          Port: data?.port
                        }
                      }
                  });
                  if(addEmailCredentials?.success){
                    onClose();
                  }else {
                    enqueueSnackbar("Error", {variant: "error"});
                  }
            }

          } catch (error) {
            console.error(error);
          }
      });

      useEffect(() => {
        if(open){
            const credentials = emailCred?.getEmailCredentials?.results[0];
            if (credentials && credentials !== emailCred) {
                setEmailCred(credentials);
                reset({
                  clientid: credentials?.CLIENT_ID,
                  clientsecret: credentials?.CLIENT_SECRET,
                  redirecturl: credentials?.REDIRECT_URI,
                  refreshtoken: credentials?.REFRESH_TOKEN,
                  host: credentials?.Host,
                  port: credentials?.Port,
                  email: credentials?.Email,
                  password: credentials?.Password,
                  service: credentials?.Service
                });
              }
        }
    }, [emailCred, open, setEmailCred, reset])



   
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Email Settings</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
            >

              <RHFTextField name="clientid" label="Client ID" />
              <RHFTextField name="clientsecret" label="Client Secret" />
              <RHFTextField name="refreshtoken" label="Refresh Token" />

            </Box>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
             <RHFTextField name="host" label="Host" />
              <RHFTextField name="port" label="Port" />
              <RHFTextField name="email" label="Email" />
              <RHFTextField name="password" label="App Password" />
              <RHFSelect
                name="service"
                label="Service"
                InputLabelProps={{ shrink: true }}
                
              >
                {service?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    {item.label}
                  </MenuItem>
                  )
                })}
              </RHFSelect>
              <RHFTextField name="redirecturl" label="Redirect URL" />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
        <LoadingButton type="submit" variant="contained" loading={isLoading}>Save</LoadingButton>
              <LoadingButton variant="contained" loading={isLoading} onClick={onClose}>Close</LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

EmailSettingsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { Stack, MenuItem, Tooltip, TextField, InputLabel, IconButton, FormControl, OutlinedInput, InputAdornment, Select, Button, Typography } from '@mui/material';
import Iconify from '../../../../components/iconify/index.js';
import { color } from 'framer-motion';

export default function AssignedReportTableToolBar({
    filters,
    onFilters,
    subsOptions,
    handlePrintAssigned,
    trainer,
    handleReset,
    searchText
}){
    // const popover = usePopover();
    const handleFilterName = useCallback((event) => {
        onFilters('name', event.target.value)
    }, [onFilters])

    const handleDateFrom = useCallback((event) =>{
        onFilters('dateFrom', event)
    },[onFilters])

    const handleDateTo = useCallback((event) =>{
        onFilters('dateTo', event)
    },[onFilters])


    return (
        <>
            <Stack
                spacing={2}
                alignItems={{xs: 'flex-end', md: 'center'}}
                direction={{
                    xs: 'column',
                    md: 'row'
                }}
                sx={{
                    p: 2.5,
                    pr: {xs: 2.5, md: 1}
                }}
            >


                <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1}} >
                    {/* <TextField
                    fullWidth
                    value={filters?.name}
                    onChange={handleFilterName}
                    placeholder="Search by fullname or NRIC"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}} />
                            </InputAdornment>
                        )
                    }}
                    /> */}
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Trainer/Assessor</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Trainer/Assessor"
                        onChange={handleFilterName}
                        value={searchText?.name}
    
                    >  
                        {trainer?.results?.map((item) => {
                            return (
                                <MenuItem key={item._id} value={item.fullName} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                                {item.fullName}
                            </MenuItem>
                            )
                            })}
                    </Select>
                    </FormControl>

                    <DatePicker
                    label="Course Start Date From"
                    format='dd/MM/yyyy'
                    onChange={handleDateFrom}
                    value={searchText?.dateFrom}
                    slotProps={{
                        textField: {
                        fullWidth: true
                        },
                    }}
                    sx={{width: 410}}
                    renderInput={(params) => <TextField {...params} />}
                    />

                        <DatePicker
                    label="Course Start Date To"
                    format='dd/MM/yyyy'
                    onChange={handleDateTo}
                    value={searchText?.dateTo}
                    slotProps={{
                        textField: {
                        
                        }
                    }}
                    sx={{width: 410}}
                    renderInput={(params) => <TextField {...params} />}
                    />
                        <Tooltip title="Print">
                        <IconButton onClick={handleReset} color="warning">
                            <Iconify icon="solar:refresh-square-broken" />
                        </IconButton>
                        </Tooltip>

                        <Tooltip title="Print">
                        <IconButton onClick={handlePrintAssigned} color="info">
                            <Iconify icon="ic:round-print" />
                        </IconButton>
                        </Tooltip>
                </Stack>


            </Stack>
        </>
    )
}

AssignedReportTableToolBar.propTypes = {
    filters: PropTypes.object,
    onFilters: PropTypes.func,
    subsOptions: PropTypes.array,
    handlePrintAssigned: PropTypes.func,
    trainer: PropTypes.array,
    handleReset: PropTypes.func,
    searchText: PropTypes.any
}
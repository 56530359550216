
import { useState, useEffect, useCallback} from 'react';
import { Container, Stack, Typography, Grid, CardContent, Card, Tooltip, IconButton, useTheme, CircularProgress, Box, alpha } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSettingsContext } from '../../../components/settings/index.js';
import Iconify from '../../../components/iconify/iconify.js';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import { useSelector } from 'react-redux';


export function ClientReport({companyinfo}) {
  const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);
  const company = useSelector((state) => state?.auth?.company);
  const settings = useSettingsContext();
  const theme = useTheme();
  const navigate = useNavigate();

  const handelAttendanceList = useCallback(() => {
    navigate(`/${company}/reports/attendance`, {state: {info: companyinfo}})
});

const handelAssessmentList = useCallback(() => {
  navigate(`/${company}/reports/assessment`, {state: {info: companyinfo}})
});

const handelAssignedList = useCallback(() => {
  navigate(`/${company}/reports/assigned`, {state: {info: companyinfo}})
});

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
         <CustomBreadcrumbs
         heading={`${companyinfo?.companyName || company_name} - Reports`}
         links={[
          {name: 'Dashboard'},
          {name: 'Reports'}
         ]}
        />
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', p: 3}}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="title1">Attendance Report</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            </Box>
            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
            <Tooltip title="View Attendance Report">
            <IconButton onClick={() => handelAttendanceList()}>
              <Iconify icon={'solar:clipboard-list-linear'} width={32} sx={{ color: theme.palette.info.main, position: 'absolute' }} />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={2}
                  sx={{ color: theme.palette.info.main, opacity: 0.48 }}
                />
            </IconButton>
            </Tooltip>
              </Stack>
      </Card>

              {/* <Card>
              <CardContent
                  sx={{
                    pt: 6,
                    width: 1
                  }}
                >
                  <p>Attendance Report</p>
                  <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
                      
                        <Iconify icon={'solar:clipboard-list-linear'} width={42} sx={{ color: theme.palette.warning.main, position: 'absolute' }} />
                        <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={2}
                  sx={{ color: theme.palette.info.main, opacity: 0.48 }}
                />
                   </Stack>

                  <Stack
                  spacing={1.5}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Stack direction="row" alignItems="center">
                    <Tooltip title="View Attendance Report">
                    <IconButton size="small" color='info'>
                      <Iconify icon="solar:eye-bold" width={24} />
                    </IconButton>
                    </Tooltip>
                  
                  </Stack>
                </Stack>


                </CardContent>
              </Card> */}
          </Grid>

          <Grid item sm={12} md={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', p: 3}}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="title1">Assessment Report</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            </Box>
            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
            <Tooltip title="View Assessment Report">
            <IconButton onClick={() => handelAssessmentList()}>
              <Iconify icon={'ic:outline-assessment'} width={32} sx={{ color: theme.palette.warning.main, position: 'absolute' }} />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={2}
                  sx={{ color: theme.palette.warning.main, opacity: 0.48 }}
                />
              </IconButton>
              </Tooltip>
              </Stack>
      </Card>
          </Grid>

          <Grid item sm={12} md={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', p: 3}}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="title1">Trainer/Assessor Course Assigned</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            </Box>
            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
            <Tooltip title="View Assessor/Trainer Course Assigned Report">
            <IconButton onClick={() => handelAssignedList()}>
              <Iconify icon={'fluent-emoji-high-contrast:teacher'} width={32} sx={{ color: theme.palette.error.main, position: 'absolute' }} />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={2}
                  sx={{ color: theme.palette.error.main, opacity: 0.48 }}
                />

              </IconButton>
              </Tooltip>
              </Stack>
      </Card>
          </Grid>
        </Grid>
    </Container>
  );
}
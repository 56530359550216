import { fDate } from "./formatTime.js"
export function Template1(company, classdetails) {
    const course = classdetails?.class;
    return (
        `<p>Dear <strong>Trainee</strong>,</p>
            <br/>    
            <p>Thank you for registering with us. We are pleased to inform you that the following class is confirmed.</p>
            <br />
            <p> <strong>Course Information </strong></p>
            <p>Course: ${course?.course?.courseTitle}</p>
            <p>Course Description: ${course?.course?.courseDescription}</p>
            <br/>
            <p><strong>Schedule & Venue</strong></p>
            <p>Training Dates: ${fDate(course?.courseDates.start)} - ${fDate(course?.courseDates.end)}</p>
            <p>Location: ${course?.venue?.block} ${course?.venue?.street} ${course?.venue?.building} #${course?.venue?.floor}-${course?.venue?.unit}</p>
            <p>Singapore ${course?.venue?.postalCode} Training Room: ${course?.venue?.room}</p>
            <br/>
            <p>Payment details and class information will be sent to you soon.</p>
            <p>If you have any questions regarding the course or require other assistance, please let us know.</p>
            <br/>
        <p>Best regards,<br/>Your Training Team</p>`
    )

}



import { sum } from 'lodash';
import { fCurrency } from './format-number.js';

const customHeaders = {
    "Status": "status",
    "Invoice Number": "invoiceNumber", 
    "Bill To": "billTo",
    "Course Run Id": "courseInfo.courseRun",
    "Course Title": "courseInfo.courseTitle",
    "Course Start Date": "courseInfo.courseStart",
    "Invoice Date": "createDate",
    "Full Course Fee": "courseFee",
    "Grants Total": "totalGrants",
    "Discount Total": "discount",
    "Additional Lines Total": "additionalLine",
    "GST Amount": "gstAmount",
    "Balance Payable": "totalAmount"
  };

function exportToInvoice(jsonData, filename = 'export.csv') {
  const headers = Object.keys(customHeaders);
  // Map JSON data to include only the fields matching the custom headers
  const csvRows = jsonData.map(row =>
    headers.map(header => {
      const field = customHeaders[header];
      let value;


      // Handle date formatting specifically
      if (field === "createDate") {
        const rawDate = row[field];
        value = rawDate
          ? new Date(rawDate).toLocaleDateString('en-SG') // Format as DD/MM/YYYY
          : '';
      } else if(field === "totalGrants"){
          const grants = row[field];
          value = grants !== 0 ? `-${fCurrency(grants)}` : 0;
      } else if(field === "discount"){
          const discount = row[field];
          value = discount !== 0 ? `-${fCurrency(discount)}` : 0;
      } else if(field === "billTo"){
        const fullName = row.invoiceToInfo?.fullName;
        const companyName = row.invoiceToInfo?.empcompanyName;
        value = fullName || companyName || '';
      }else if (field === "courseFee"){
        const sumCourseFee = row?.items?.map((item) => item?.nricFin ? item?.total : 0);
        const courseFee = sum(sumCourseFee);
          value = fCurrency(courseFee) || 0;
      } else if(field === "gstAmount"){
        const totalOnRow = row?.items?.map((item) => item?.total);
        const subTotal_less = sum(totalOnRow);
        const mappedNumbers = sum(row?.items?.map(num => num?.total < 0 ? Math.abs(num?.total) : 0));
        const tax = (subTotal_less + mappedNumbers - row?.discount)  * (row?.taxes / 100); 
        value = fCurrency(tax) || 0;
      }else if (field === "additionalLine"){
        const sumadditionalLine = row?.items?.map(num => !num?.nricFin ? num?.total : 0);
        const additionalLine = sum(sumadditionalLine)
        value = fCurrency(additionalLine) || 0;
      }else if (field === "totalAmount"){
        const totalAmount = row[field];
        value = fCurrency(totalAmount) || 0;
      } else {
        // Access nested fields or flat fields
        value = field.split('.').reduce((acc, key) => acc && acc[key], row) || '';
      }

      return `"${value}"`;
    }).join(',')
  );

  // Combine custom headers and rows into a CSV string
  const csvContent = [headers.join(','), ...csvRows].join('\n');

  // Create a Blob and trigger download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export {
    exportToInvoice
}


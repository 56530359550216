
import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha, Box, Dialog, DialogActions} from '@mui/material';
import { useSettingsContext } from '../../../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../../components/table/index.js';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { PDFViewer } from '@react-pdf/renderer';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import Iconify from '../../../../components/iconify/iconify.js';
import AttendanceReportTableRow from './attendance-report-table-row.js';
import AttendanceReportTableToolBar from './attendance-report-table-toolbar.js';
import AttendancePDF from './attendance-view-pdf.js';
import Label from '../../../../components/label/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';



// GRAPHQL
import { GET_CLASSES, DELETE_CLASSES, CANCELLED_CLASS, COMPLETED_CLASS, REVERT_CLASS } from "../../../../graphql/modules/_client/class.js";
 import { useQuery, useMutation } from "@apollo/react-hooks";

const STATUS_OPTION = [
  {value: 'all', label: 'All', role: ['superadmin','admin', 'bo', 'tm', 'ta']},
  {value: 'confirmed', label: 'Confirmed', role: ['superadmin','admin', 'bo', 'tm', 'ta']},
  {value: 'completed', label: 'Completed', role: ['superadmin','admin', 'bo', 'tm', 'ta']},
  {value: 'draft', label: 'Draft', role: ['superadmin','admin', 'bo', 'tm']},
  {value: 'cancelled', label: 'Cancelled', role: ['superadmin','admin', 'bo', 'tm']},
]
// const STATUS_OPTION_TA = [
//   {value: 'all', label: 'All'},
//   {value: 'confirmed', label: 'Confirmed'},
//   {value: 'completed', label: 'Completed'},
// ]
const TABLE_HEAD = [
  {id: 'courseRunId', label: 'Course Run ID'},
  {id: 'courseDates.start', label: 'Start Date'},
  {id: 'classCode', label: 'Class Code'},
  {id: 'courseTitle', label: 'Course Title'},
  {id: 'venue', label: 'Attendance Rate %'},
  {id: 'trainers', label: 'Attrition Rate %'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function AttendanceListViewReport({companyinfo}) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const {nricFin} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const isRole = useSelector((state) => state?.auth?.isRole);
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const attendanceDG = useBoolean();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const [classAtt, setClassAtt] = useState(null);
  const [attritionRate, setAttritionRate] = useState(null);


  const [DeleteClassMutation] =  useMutation(DELETE_CLASSES);
  const [CancelledClassMutation] =  useMutation(CANCELLED_CLASS);
  const [CompleteClassMutation] =  useMutation(COMPLETED_CLASS);
  const [RevertClassMutation] =  useMutation(REVERT_CLASS);

  

  const { data, loading, error, refetch } = useQuery(GET_CLASSES, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: state?.databaseName || company,
      order: table.order || 'desc',
      orderBy: table.orderBy || 'courseDates.start',
      filterBy: nricFin,
      isRole: isRole
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getClasses)
      }
  }, [data])


    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
    const handleDeleteRow = useCallback(async (row) => {
      const {data: { deleteClass }} = await DeleteClassMutation({
        variables: {
          _id: row?._id,
          companyName: state?.databaseName || company,
          fullName: fullName
        }
      })
      if(deleteClass?.success){
        enqueueSnackbar(deleteClass?.message, {variant: 'success'});
        refetch();
      }else {
        enqueueSnackbar(deleteClass?.message, {variant: 'error'});
      }
      }, [refetch]);

      const handleCancelledRow = useCallback(async (row) => {
        const {data: { cancelledClass }} = await CancelledClassMutation({
          variables: {
            _id: row?._id,
            companyName: state?.databaseName || company,
            fullName: fullName,
            courseRunId: row?.courseRunId,
            courseReferenceNumber: row?.course?.tsgRefNumber,
            status: row?.status,
            uen: companyProfile?.uen,
            encryptionkey: companyProfile?.encryptionKey,
            _companyName: companyProfile?.companyName,
          }
        })
        if(cancelledClass?.success){
          enqueueSnackbar(cancelledClass?.message, {variant: 'success'});
          refetch();
        }else {
          enqueueSnackbar(cancelledClass?.message, {variant: 'error'});
        }
        }, [refetch]);

        const handleCompleted = useCallback(async (row) => {
          const {data: { completedClass }} = await CompleteClassMutation({
            variables: {
              _id: row?._id,
              companyName: state?.databaseName || company,
              fullName: fullName
            }
          })
          if(completedClass?.success){
            enqueueSnackbar(completedClass?.message, {variant: 'success'});
            refetch();
          }else {
            enqueueSnackbar(completedClass?.message, {variant: 'error'});
          }
          }, [refetch]);

          const handleRevertBack = useCallback(async (row) => {
            const {data: { revertClass }} = await RevertClassMutation({
              variables: {
                _id: row?._id,
                companyName: state?.databaseName || company,
                fullName: fullName
              }
            })
            if(revertClass?.success){
              enqueueSnackbar(revertClass?.message, {variant: 'success'});
              refetch();
            }else {
              enqueueSnackbar(revertClass?.message, {variant: 'error'});
            }
            }, [refetch]);

    
    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update-classes', {state: {info: companyinfo, classInfo: row}})
  });

const handleView = useCallback(
  (row) => {
    setClassAtt(row)
    attendanceDG.onTrue();
  },
  []
);

const handleEdit = useCallback(
  (id) => {
    console.log(id)
    // router.push(paths.dashboard.job.edit(id));
  },
  []
);


const handleDelete = useCallback((id) => {
  console.info('DELETE', id);
}, []);

const handleCreateNewClass = useCallback(() => {
    navigate(`/${company}/reports`, {state: {info: companyinfo}})
 
});


  return (
     <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${companyinfo?.companyName || company_name} - Attendance Report`}
          links={[
           {name: 'Dashboard'},
           {name: 'Attendance Report'}
          ]}
          action ={
           <Button
           componet={RouterLink}
           onClick={handleCreateNewClass}
           variant="contained"
           startIcon={<Iconify icon="mingcure:add-line" />}
           sx={{
            display: isRole.some((role)=> ["bo", "tm"].includes(role)) ?  '' : 'none'
           }}
           >
            Go Back
          </Button>
        }
       />

  

        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'confirmed' && 'success') ||  (tab.value === 'completed' && 'info') ||  (tab.value === 'draft' && 'warning')  ||  (tab.value === 'cancelled' && 'error') || 'default'
                }
                >
                  {tab.value === 'all' && results?.count}
                  {tab.value === 'confirmed' && results?.confirmed}
                  {tab.value === 'completed' && results?.completed}
                  {tab.value === 'draft' && results?.draft}
                  {tab.value === 'cancelled' && results?.cancelled}
              </Label>
              }
              sx={{
                display: isRole.some((role)=> tab?.role?.includes(role)) ? '' : 'none'
              }}
           />
  
          ))}

         </Tabs>

          <AttendanceReportTableToolBar onFilters={handleFilters} />
          <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row) => (
                      <AttendanceReportTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleView(row)}
                      onDeleteRow={() => handleDeleteRow(row)}
                      onEditRow={() => handleEditRow(row)}
                      onCancelledRow={() => handleCancelledRow(row)}
                      onCompleted={() => handleCompleted(row)}
                      onRevert={() => handleRevertBack(row)}
                      setAttritionRate={setAttritionRate}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>
          </Scrollbar>

          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

        <Dialog fullScreen open={attendanceDG.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={attendanceDG.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <AttendancePDF attendance={classAtt} companyProfile={companyProfile} attritionRate={attritionRate}/>
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>

    </Container>
  );
}

AttendanceListViewReport.propTypes = {
  companyinfo: PropTypes.object,
};
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import { alpha, Box, Paper, Stack, Button,  Portal, Backdrop , InputBase, IconButton, Typography, Autocomplete, TextField, Avatar, Chip} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// hooks
import { useBoolean } from 'src/hooks/use-boolean.js';
import { useResponsive } from 'src/hooks/useResponsive.js';
import { useSnackbar } from 'src/components/snackbar/index.js';
// components
import Iconify from "src/components/iconify/iconify.js";
import Editor from 'src/components/editor';
import { fDate } from 'src/util/formatTime.js';
import {Template1} from 'src/util/emailTemplate';
import {SEND_EMAIL} from 'src/graphql/modules/_client/sendemail.js';
import { useMutation } from "@apollo/react-hooks";


// ----------------------------------------------------------------------

const ZINDEX = 1998;

const POSITION = 24;

export default function MailCompose({ onCloseCompose, classdetails, credentials }) {
  const course = classdetails?.class;
  const smUp = useResponsive('up', 'sm');
  const trainerAss = course?.assessors?.map((res) => res)
  const trainee = classdetails?.class?.enrolment?.map((res) => res?.traineeInfo)
  const company = useSelector((state) => state?.auth?.company);
  const [SendEmailMutation] =  useMutation(SEND_EMAIL);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)

  const [values, setValues] = useState({
    to: trainee,
    cc: trainerAss,
    subject: `Confirmed Class - ${course?.course?.courseTitle} on ${fDate(course?.courseDates.start)}` || '',
    emailMessage: Template1("iatos", classdetails) || '',
    credentials: credentials
  })
  
//   useEffect(() => {
//     setValues(classdetails)
// }, [classdetails])

  const [message, setMessage] = useState('');

  const fullScreen = useBoolean();

  const handleChangeMessage = useCallback((value) => {
    setMessage(value);
  }, []);

  const handleChangeTo = (event, newValue) => {
    setValues({ ...values, to: newValue });
  };
  const handleChangeCC = (event, newValue) => {
    setValues({ ...values, cc: newValue });
  };

  const handleSendEmail = (async() =>{
    try {
      setIsLoading(true)
      const {data: {sendEmail}} = await SendEmailMutation({
        variables: {
          companyName: company,
            data: {
              service: credentials?.service,
              CLIENT_ID: credentials?.CLIENT_SECRET,
              CLIENT_SECRET: credentials?.CLIENT_SECRET,
              REFRESH_TOKEN: credentials?.REFRESH_TOKEN,
              REDIRECT_URI: credentials.REDIRECT_URI,
              Email: credentials?.Email,
              Password: credentials?.Password,
              service: credentials?.Service
            },
            mail: {
              from: credentials?.Email,
              to: values?.to?.map((res) => res?.email),
              cc: values?.cc?.map((res) => res?.email),
              subject: values?.subject,
              text: values?.emailMessage
            }
          }
      })
      if(sendEmail?.success){
        enqueueSnackbar("Email sent!", {variant: "success"});
        setIsLoading(false)
      }else{
        enqueueSnackbar("Server Error", {variant: "error"});
      }
    } catch (error){
      enqueueSnackbar("Server Error", {variant: "error"});
    }
  })



  return (
    <Portal>
      {(fullScreen.value || !smUp) && <Backdrop open />}

      <Paper
        sx={{
          right: 0,
          bottom: 0,
          borderRadius: 2,
          display: 'flex',
          position: 'fixed',
          zIndex: 1200,
          m: `${POSITION}px`,
          overflow: 'hidden',
          flexDirection: 'column',
          boxShadow: (theme) => theme.customShadows.dropdown,
          ...(fullScreen.value && {
            m: 0,
            right: POSITION / 2,
            bottom: POSITION / 2,
            width: `calc(100% - ${POSITION}px)`,
            height: `calc(100% - ${POSITION}px)`,
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            bgcolor: 'background.neutral',
            p: (theme) => theme.spacing(1.5, 1, 1.5, 2)
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            New Message
          </Typography>

          <IconButton onClick={fullScreen.onToggle}>
            <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} />
          </IconButton>

          <IconButton onClick={onCloseCompose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>

        {/* <InputBase
          placeholder="To"
          sx={{
            px: 2,
            height: 48,
            borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
          value={values?.to}
        /> */}
              
                  <Autocomplete
                    multiple
                    id="trainee"
                    value={values?.to}
                    onChange={handleChangeTo}
                    options={trainee}
                    getOptionLabel={(option) => option?.fullName || "No Name"}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, _trainee) => (
                      <li {...props} key={_trainee._id}>
                        <Avatar
                          key={_trainee._id}
                          alt={_trainee.fullName}
                          src={_trainee.fullName}
                          sx={{ width: 24, height: 24, flexShrink: 0, mr: 1 }}
                        />
    
                        {_trainee.fullName}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key} // Unique key for each chip
                            label={option.fullName}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="To" placeholder="Add trainee"/>
                    )}
                    sx={{
                      px: 2,
                      mb: 3,
                      borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`
                    }}                    
                  />
             

              <Autocomplete
                    multiple
                    id="trainerAssessor"
                    value={values?.cc}
                    onChange={handleChangeCC}
                    options={trainerAss}
                    getOptionLabel={(option) => option.fullName}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key}
                            label={option.fullName}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderOption={(props, _trainerass) => (
                      <li {...props} key={_trainerass._id}>
                        <Avatar
                          key={_trainerass.id}
                          alt={_trainerass.fullName}
                          src={_trainerass.fullName}
                          sx={{ width: 24, height: 24, flexShrink: 0, mr: 1 }}
                        />
    
                        {_trainerass.fullName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="CC"/>
                    )}
                    sx={{
                      px: 2,
                      height: 48,
                      mb: 3,
                      borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                    }}
                  />

        <InputBase
          placeholder="Subject"
          sx={{
            px: 2,
            height: 48,
            borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
          value={values?.subject}
        />

        <Stack spacing={2} flexGrow={1} sx={{ p: 2 }}>



          <Editor
            simple
            id="compose-mail"
            value={values?.emailMessage}
            onChange={handleChangeMessage}
            placeholder="Type a message"
            sx={{
              '& .ql-editor': {},
              ...(fullScreen.value && {
                height: 1,
                '& .quill': {
                  height: 1,
                },
                '& .ql-editor': {
                  maxHeight: 'unset',
                },
              }),
            }}
          />

          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center" flexGrow={1}>
              {/* <IconButton>
                <Iconify icon="solar:gallery-add-bold" />
              </IconButton>

              <IconButton>
                <Iconify icon="eva:attach-2-fill" />
              </IconButton> */}
              
            </Stack>

            <LoadingButton
              variant="contained"
              color="primary"
              endIcon={<Iconify icon="iconamoon:send-fill" />}
              onClick={handleSendEmail}
              loading={isLoading}
            >
              Send
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </Portal>
  );
}

MailCompose.propTypes = {
  onCloseCompose: PropTypes.string,
  classdetails: PropTypes.any
};

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useState , useEffect} from 'react';
// @mui
import {Chip, Card, Paper, Stack, Avatar, Grid, Typography, ListItemText, Container, Link, Button} from '@mui/material';
// utils
import { yupResolver } from '@hookform/resolvers/yup';
import { fDate, fTime } from '../../../../util/formatTime.js';
import { fCurrency } from '../../../../util/format-number.js';
import {courseMode } from '../../../../util/course.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
// components
import Iconify from 'src/components/iconify';
// import MailCompose from './mail-compose.js'
import { GET_COURSE_RUN_ID } from "../../../../graphql/modules/_client/class.js";
import {GET_EMAIL_CREDENTIALS} from 'src/graphql/modules/_client/sendemail.js';
 import { useQuery } from "@apollo/react-hooks";
 import MailCompose from '../email/mail-compose.js';


// import Markdown from 'src/components/markdown';

// ----------------------------------------------------------------------


export default function ClassDetailsContent({ classdetails }) {
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const isRole = useSelector((state) => state?.auth?.isRole);
  const [results, setResults] = useState(null);
  const [credentials, setCredentials] = useState(null);
  const company = useSelector((state) => state?.auth?.company);
  const venueDetails = `${classdetails?.class?.venue?.block} ${classdetails?.class?.venue?.street} #${classdetails?.class?.venue?.unit} ${classdetails?.class?.venue?.room} ${classdetails?.class?.venue?.building} ${classdetails?.class?.venue?.postalCode}`;
  const postalCode = classdetails?.class?.venue?.postalCode;
  const wheelChairAccess = classdetails?.class?.venue?.wheelChairAccess;
  const isAdmin = isRole.some((role)=> ["bo"].includes(role))
  const openCompose = useBoolean();

  const { data: emailCred  } = useQuery(GET_EMAIL_CREDENTIALS, {
    variables: {
      companyName: company || ''
      },
      fetchPolicy: "cache-and-network",
    });

  const { data, loading, error, refetch } = useQuery(GET_COURSE_RUN_ID, {
    variables: {
      uen: companyProfile?.uen,
      courseReferenceNumber: classdetails?.class?.course?.tsgRefNumber,
      runid: classdetails?.class?.courseRunId,
      encryptionkey: companyProfile?.encryptionKey,
      _companyName: companyProfile?.companyName,
      },
      fetchPolicy: "cache-and-network",
    });

    const handleToggleCompose = () => {
      openCompose.onToggle();
    };


    useEffect(() => {
      if(data){
        setResults(data?.getCourseRunId)
      }
      if(emailCred){
        setCredentials(emailCred?.getEmailCredentials?.results[0])
      }
  }, [data, emailCred])
  




  const renderContent = (
    <Stack component={Card} spacing={3} sx={{ p: 3 }}>
      <Typography variant="h6">Attendance QR Code</Typography>

      <Stack spacing={2}>
        <Stack justifyContent="center" direction="row" alignItems="center" spacing={1}>
          <Typography variant='subtitle1'>Qr Code <Link href={results?.results?.course?.run?.qrCodeLink} rel="noopener" target="_blank">{results?.results?.course?.run?.qrCodeLink}</Link> </Typography>
        </Stack>
        {
          isAdmin ?
          <Button onClick={handleToggleCompose}variant='contained' color='info'  endIcon={<Iconify icon="ic:baseline-mail" />}>Compose Email</Button>
          :
          ""
        }
       
      </Stack>



    </Stack>
  );

  const renderOverview = (
    <Stack component={Card} spacing={2} sx={{ p: 3 }}>
      {[
        {
          label: 'Course Name',
          value: classdetails?.class?.course?.courseTitle,
          icon: <Iconify icon="ic:outline-class" />,
        },
        {
          label: 'Course Run  ID',
          value: classdetails?.class?.courseRunId,
          icon: <Iconify icon="ic:outline-class" />,
        },
        {
          label: 'Class Code',
          value: classdetails?.class?.classCode,
          icon: <Iconify icon="foundation:social-skillshare" />,
        },
        {
          label: 'Start Date',
          value: fDate(classdetails?.class?.courseDates.start),
          icon: <Iconify icon="solar:calendar-date-bold" />,
        },
        {
          label: 'End Date',
          value: fDate(classdetails?.class?.courseDates.end),
          icon: <Iconify icon="solar:calendar-date-bold" />,
        },
        {
          label: 'Medium of Instruction',
          value: classdetails?.class?.course?.mediumInstruction, 
          icon: <Iconify icon="material-symbols:language" />,
        },
        {
          label: 'Mode of Training',
          value: courseMode?.map((res) => {
            if(classdetails?.class?.modeOfTraining === String(res.id)){
              return res?.value
            }
          }),
          icon: <Iconify icon="healthicons:i-training-class" />,
        },
        {
          label: 'Sessions',
          value: classdetails?.class?.sessions?.map((res) => {
            return (
              <>
              <Typography variant='subtitle'>Date: {fDate(res.startDate)} - {fTime(res?.startTime)} to {fTime(res?.endTime)}</Typography>
              <br />
              </>
            )
          }),
          icon: <Iconify icon="solar:clock-circle-bold" />
        },
      ].map((item) => (
        <Stack key={item.label} spacing={1.5} direction="row">
          {item.icon}
          <ListItemText
            primary={item.label}
            secondary={item.value}
            primaryTypographyProps={{
              typography: 'body2',
              color: 'text.secondary',
              mb: 0.5,
            }}
            secondaryTypographyProps={{
              typography: 'subtitle2',
              color: 'text.primary',
              component: 'span',
            }}
          />
        </Stack>
      ))}
    </Stack>
  );

  const renderVenue = (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      direction="row"
      sx={{ p: 3, borderRadius: 2, mt: 3 }}
    >
       
      <Stack spacing={1}>
      <Typography variant="subtitle1" color="secondary">Training Venue</Typography>
      <Typography variant="subtitle1">{classdetails?.class?.venue?.block} {classdetails?.class?.venue?.street} {classdetails?.class?.venue?.building} #{classdetails?.class?.venue?.floor}-{classdetails?.class?.venue?.unit}</Typography>
      <Typography variant="body2">Singapore {classdetails?.class?.venue?.postalCode}, Training Room: {classdetails?.class?.venue?.room}</Typography>
      <Typography variant="body2">Wheelchair Access: {classdetails?.class?.venue?.wheelChairAccess ? "Yes" : "No"}</Typography>
      </Stack>
    </Stack>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {renderContent}
      </Grid>

      <Grid item xs={12} md={4}>
        {renderOverview}

        {renderVenue}

        {openCompose.value && <MailCompose onCloseCompose={openCompose.onFalse} classdetails={classdetails} credentials={credentials}/>}
      </Grid>

    </Grid>
  );
}

ClassDetailsContent.propTypes = {
  classdetails: PropTypes.object,
};


import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {result, sumBy} from 'lodash';
import {CircularProgress, Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha, Stack, Box, Dialog, Divider, DialogActions} from '@mui/material';
import { useSettingsContext } from '../../../../components/settings/index.js';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../../components/table/index.js';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../../routes/components/index.js';
import Iconify from '../../../../components/iconify/iconify.js';
import InvoiceTableToolBar from './invoice-table-toolbar.js';
import InvoiceTableRow from './invoice-table-row.js';
import Label from '../../../../components/label/index.js';
import Scrollbar from '../../../../components/scrollbar/scrollbar.js';
import InvoiceAnalytic from './invoice-analytics.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import InvoicePDF from './invoice-pdf-view.js'
import {exportToInvoice} from '../../../../util/exportInvoice.js';
import {SSGDate} from '../../../../util/formatTime.js'

import { GET_CLIENT_INVOICE, DELETE_CLIENT_INVOICE } from "../../../../graphql/modules/_client/invoiceClient.js";
import { useQuery, useMutation } from "@apollo/react-hooks";


const STATUS_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'paid', label: 'Paid'},
  {value: 'pending', label: 'Pending'},
  {value: 'overdue', label: 'Overdue'},
  {value: 'draft', label: 'Draft'},
  {value: 'cancelled', label: 'Cancelled'}
]


const TABLE_HEAD = [
  {id: 'invoiceNumber', label: 'Invoice Number'},
  {id: 'courseInfo.courseRun', label: 'Course Run ID'},
  {id: 'courseInfo.courseTitle', label: 'Course Title'},
  {id: 'invoiceToInfo.empcompanyName', label: 'Bill To'},
  {id: 'discount', label: 'Discount'},
  {id: 'totalGrants', label: 'Grants'},
  {id: 'totalAmount', label: 'Balance Payable'},
  {id: 'createDate', label: 'Invoice Date'},
  {id: 'status', label: 'Status'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [
    {id: 'basic', label: 'Basic'},
    {id: 'standard', label: 'Standar'},
    {id: 'premium', label: 'Premium'}
  ],
  status: 'all'
}
const filterInvoice = {
  name: '',
  dateFrom: null,
  dateTo: null
}


export function InvoiceListView({img, ...other}) {
  const fullName = useSelector((state) => state?.auth?.user?.fullName);
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState(filterInvoice);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [DeleteInvoiceMutation] =  useMutation(DELETE_CLIENT_INVOICE);
  const [invoiceDetails, setInvoice] = useState(null);
  const invoicePdf = useBoolean();
  const [exportData, setExportData] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_CLIENT_INVOICE, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: companyProfile?.databaseName,
      order: table.order || 'desc',
      orderBy: table.orderBy || 'createDate'
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getAllClientInvoice)
      }
  }, [data])

  const getInvoiceLength = (status) => results?.results?.filter((item) => item.status === status).length;
  const getPercentByStatus = (status) => (getInvoiceLength(status) / results?.results?.length) * 100;
  const getTotalAmount = (status) =>
  sumBy(
    results?.results?.filter((item) => item.status === status),
    'totalAmount'
  );

  const handleFilters = useCallback((name, value) => {
    setSearch(prevState => ({
      ...prevState, 
      [name]: value 
    }));
  },[table]
);
const onFilterReset = useCallback(() => {
  setSearch(filterInvoice);
}, [table]);


const onExportCSV = async () => {
  const dateFrom = searchText.dateFrom ? SSGDate(searchText.dateFrom) : "";
  const dateTo= searchText.dateTo ? SSGDate(searchText.dateTo) : "";
  const eData = await refetch({ 
    limit: 0,
    offset: 0,
    searchText: searchText,
    companyName: companyProfile?.databaseName,
    order: table.order || 'desc',
    orderBy: table.orderBy || 'createDate'
  });
  const exportData = eData?.data?.getAllClientInvoice;
  exportToInvoice(exportData?.results, `Invoice ${dateFrom} - ${dateTo}`)
};


  const handleViewInvoice = useCallback((row) => {
      setInvoice(row);
      invoicePdf.onTrue();
  },
  []
);

const handleEditRow = useCallback((row) => {
  navigate('/company-invoice-create-update', {state: row})
});

const handleDeleteRow = useCallback(async (id) => {

  const {data: { deleteClientInvoice }} = await DeleteInvoiceMutation({
    variables: {
      _id: id,
      companyName: companyProfile?.databaseName,
      fullName: fullName,
    }
  })

  if(deleteClientInvoice?.success){
    refetch();
    enqueueSnackbar('Deleted success!', {variant: 'success'});
  }else {
    enqueueSnackbar('Failed to  delete!', {variant: 'error'});
  }
  },
  [refetch]
);

const handleFilterStatus = useCallback(
  (event, newValue) => {
    if(newValue !== "all"){
      setFilters(newValue);
      setSearch(newValue);
    }else {
      setFilters(newValue);
      setSearch('');
    }

  },
  [table]
);
const handleCreateNewInvoice = useCallback(() => {
  navigate('/company-invoice-create-update')

});

const handleCreateNewReceipt = useCallback((state) => {
  navigate('/company-receipt-create-update', {state: state})

});

const handleDownload = async () => {
  const doc = <InvoicePDF invoiceDetails={invoiceDetails} companyProfile={companyProfile}/>;
  const asBlob = await pdf(doc).toBlob(); 
  const url = URL.createObjectURL(asBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = invoiceDetails?.invoiceNumber ? invoiceDetails?.invoiceNumber : invoiceDetails?.invoiceToInfo?.fullName ? invoiceDetails?.invoiceToInfo?.fullName : invoiceDetails?.invoiceToInfo?.empcompanyName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>

<CustomBreadcrumbs
         heading="Invoice List"
         links={[
          {name: 'Invoice'},
          {name: 'List'}
         ]}
         action ={
          <Button
          componet={RouterLink}
          onClick={handleCreateNewInvoice}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          >
            New Invoice
          </Button>
         }
        />


            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <InvoiceAnalytic
                title="Total"
                total={results?.count}
                percent={100}
                price={sumBy(results?.results, 'totalAmount')}
                icon="solar:bill-list-bold-duotone"
                color={theme.palette.info.main}
              />

              <InvoiceAnalytic
                title="Paid"
                total={getInvoiceLength('paid')}
                percent={getPercentByStatus('paid')}
                price={getTotalAmount('paid')}
                icon="solar:file-check-bold-duotone"
                color={theme.palette.success.main}
              />

              <InvoiceAnalytic
                title="Pending"
                total={getInvoiceLength('pending')}
                percent={getPercentByStatus('pending')}
                price={getTotalAmount('pending')}
                icon="solar:sort-by-time-bold-duotone"
                color={theme.palette.warning.dark}
              />

              <InvoiceAnalytic
                title="Overdue"
                total={getInvoiceLength('overdue')}
                percent={getPercentByStatus('overdue')}
                price={getTotalAmount('overdue')}
                icon="solar:bell-bing-bold-duotone"
                color={theme.palette.secondary.dark}
              />

              <InvoiceAnalytic
                title="Draft"
                total={getInvoiceLength('draft')}
                percent={getPercentByStatus('draft')}
                price={getTotalAmount('draft')}
                icon="solar:file-corrupted-bold-duotone"
                color={theme.palette.text.secondary}
              />

              <InvoiceAnalytic
                title="Cancelled"
                total={getInvoiceLength('cancelled')}
                percent={getPercentByStatus('cancelled')}
                price={getTotalAmount('cancelled')}
                icon="solar:file-corrupted-bold-duotone"
                color={theme.palette.error.dark}
              />
            </Stack>
         

        <Card>

        <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'paid' && 'success') ||  (tab.value === 'pending' && 'warning') || (tab.value === 'overdue' && 'secondary') || (tab.value === 'cancelled' && 'error') ||'default'
                }
                >
                  {tab.value === 'all' && results?.count}
                  {tab.value === 'paid' && results?.paid}
                  {tab.value === 'pending' && results?.pending}
                  {tab.value === 'overdue' && results?.overdue}
                  {tab.value === 'draft' && results?.draft}
                  {tab.value === 'cancelled' && results?.cancelled}
              </Label>
              }
           />
  
          ))}

         </Tabs>

        <InvoiceTableToolBar  onFilters={handleFilters} onFilterReset={onFilterReset} filterInvoice={searchText} onExportCSV={onExportCSV}/>
          <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
 

                   <TableBody>

                {results?.results?.map((row) => (
                      <InvoiceTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleViewInvoice(row)} 
                      onEditRow={() => handleEditRow(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onReceipt={() => handleCreateNewReceipt(row)}
                    />
                ))}
  

                </TableBody>


              </Table>
          </TableContainer>
          </Scrollbar>
          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

        <Dialog fullScreen open={invoicePdf.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
          <Button color="inherit" variant="contained" onClick={handleDownload}>
              Download
            </Button>
            <Button color="inherit" variant="contained" onClick={invoicePdf.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDF invoiceDetails={invoiceDetails} companyProfile={companyProfile}/>
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>

    </Container>
  );
}